import type { getSiteData } from './getSiteData';

type WithSiteData = {
  siteData: Awaited<ReturnType<typeof getSiteData>>;
};

export function createSiteMeta(data?: WithSiteData) {
  return [
    { title: 'Zenobius' },
    { name: 'description', content: data?.siteData.description || '' },
  ];
}
